var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center justify-center flex-wrap",attrs:{"cols":"12"}},[(_vm.researches)?_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","outlined":""},on:{"click":_vm.exportTable}},[_c('v-icon',[_vm._v("mdi-file-excel-outline")]),_c('span',[_vm._v("Exporter cette étude")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"by-1 br-1 bl-1 text-center"},[_vm._v("Équipe")]),_c('th',{staticClass:"by-1 br-1 text-center"},[_vm._v("Budget social")]),_c('th',{staticClass:"by-1 br-1 text-center"},[_vm._v("Nombre d'ouvriers")]),_c('th',{staticClass:"by-1 br-1 text-center"},[_vm._v("Nombre d'employés")]),_c('th',{staticClass:"by-1 br-1 text-center"},[_vm._v(" Nombre de chefs de production ")]),_c('th',{staticClass:"by-1 br-1 text-center"},[_vm._v("Nombre de manageurs")]),_c('th',{staticClass:"by-1 br-1 text-center"},[_vm._v("Nombre de Commerciaux")]),_c('th',{staticClass:"by-1 br-1 text-center"},[_vm._v("Indice moyen des ouvriers")]),_c('th',{staticClass:"by-1 br-1 text-center"},[_vm._v("Indice moyen des employés")]),_c('th',{staticClass:"by-1 br-1 text-center"},[_vm._v(" Indice moyen des chefs de production ")]),_c('th',{staticClass:"by-1 br-1 text-center"},[_vm._v("Indice moyen des manageurs")]),_c('th',{staticClass:"by-1 br-1 text-center"},[_vm._v(" Indice moyen des Commerciaux ")]),_c('th',{staticClass:"by-1 br-1 text-center"},[_vm._v(" Commission moyen des Commerciaux ")])])]),_c('tbody',_vm._l((_vm.filteredTeams),function(team){return _c('tr',[(
                _vm.researches &&
                _vm.researches.hrResearch &&
                _vm.researches.hrResearch[team.id]
              )?[_c('td',{staticClass:"bb-1 br-1 bl-1 text-center",domProps:{"textContent":_vm._s(team.name + '-' + team.publicName)}}),(_vm.researches.hrResearch[team.id].socialBudget)?_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatMoney(
                    _vm.researches.hrResearch[team.id].socialBudget
                  )
                )}}):_vm._e(),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(_vm.researches.hrResearch[team.id].staffAmount.workers)}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(_vm.researches.hrResearch[team.id].staffAmount.employees)}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.researches.hrResearch[team.id].staffAmount.productManagers
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.researches.hrResearch[team.id].staffAmount.serviceManagers
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(_vm.researches.hrResearch[team.id].staffAmount.salesmen)}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatPercentage(
                    _vm.researches.hrResearch[team.id].socialIndex.workers
                  )
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatPercentage(
                    _vm.researches.hrResearch[team.id].socialIndex.employees
                  )
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatPercentage(
                    _vm.researches.hrResearch[team.id].socialIndex.productManagers
                  )
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatPercentage(
                    _vm.researches.hrResearch[team.id].socialIndex.serviceManagers
                  )
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatPercentage(
                    _vm.researches.hrResearch[team.id].socialIndex.salesmen
                  )
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatPercentage(
                    _vm.researches.hrResearch[team.id].commission
                  )
                )}})]:_vm._e()],2)}),0)])],1)],1),_c('div',{staticClass:"mt-10"},[_c('h2',{staticClass:"mb-5 text-center"},[_vm._v("Effectifs :")]),_c('div',{staticClass:"d-flex flex-row align-center justify-center flex-wrap"},[_c('div',{staticClass:"ma-5",staticStyle:{"height":"450px","width":"100%"}},[_c('VueApexCharts',{attrs:{"options":_vm.computeOptions(),"series":_vm.computeSeries()}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }