<template>
  <div class="mt-5">
    <v-row>
      <v-col
        cols="12"
        class="d-flex flex-row align-center justify-center flex-wrap"
      >
        <v-btn
          class="ma-2"
          color="success"
          outlined
          @click="exportTable"
          v-if="researches"
        >
          <v-icon>mdi-file-excel-outline</v-icon>
          <span>Exporter cette étude</span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th class="by-1 br-1 bl-1 text-center">Équipe</th>
              <th class="by-1 br-1 text-center">Budget social</th>
              <th class="by-1 br-1 text-center">Nombre d'ouvriers</th>
              <th class="by-1 br-1 text-center">Nombre d'employés</th>
              <th class="by-1 br-1 text-center">
                Nombre de chefs de production
              </th>
              <th class="by-1 br-1 text-center">Nombre de manageurs</th>
              <th class="by-1 br-1 text-center">Nombre de Commerciaux</th>
              <th class="by-1 br-1 text-center">Indice moyen des ouvriers</th>
              <th class="by-1 br-1 text-center">Indice moyen des employés</th>
              <th class="by-1 br-1 text-center">
                Indice moyen des chefs de production
              </th>
              <th class="by-1 br-1 text-center">Indice moyen des manageurs</th>
              <th class="by-1 br-1 text-center">
                Indice moyen des Commerciaux
              </th>
              <th class="by-1 br-1 text-center">
                Commission moyen des Commerciaux
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="team in filteredTeams">
              <template
                v-if="
                  researches &&
                  researches.hrResearch &&
                  researches.hrResearch[team.id]
                "
              >
                <td
                  class="bb-1 br-1 bl-1 text-center"
                  v-text="team.name + '-' + team.publicName"
                ></td>
                <td
                  v-if="researches.hrResearch[team.id].socialBudget"
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatMoney(
                      researches.hrResearch[team.id].socialBudget
                    )
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="researches.hrResearch[team.id].staffAmount.workers"
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="researches.hrResearch[team.id].staffAmount.employees"
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    researches.hrResearch[team.id].staffAmount.productManagers
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    researches.hrResearch[team.id].staffAmount.serviceManagers
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="researches.hrResearch[team.id].staffAmount.salesmen"
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatPercentage(
                      researches.hrResearch[team.id].socialIndex.workers
                    )
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatPercentage(
                      researches.hrResearch[team.id].socialIndex.employees
                    )
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatPercentage(
                      researches.hrResearch[team.id].socialIndex.productManagers
                    )
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatPercentage(
                      researches.hrResearch[team.id].socialIndex.serviceManagers
                    )
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatPercentage(
                      researches.hrResearch[team.id].socialIndex.salesmen
                    )
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatPercentage(
                      researches.hrResearch[team.id].commission
                    )
                  "
                ></td>
              </template>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <div class="mt-10">
      <h2 class="mb-5 text-center">Effectifs :</h2>
      <div class="d-flex flex-row align-center justify-center flex-wrap">
        <div class="ma-5" style="height: 450px; width: 100%">
          <VueApexCharts
            :options="computeOptions()"
            :series="computeSeries()"
          ></VueApexCharts>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import dataFormater from "@/assets/functions/dataFormater.js";
import dataExtractor from "@/assets/functions/dataExtractor.js";

import Univers from "@/classes/Univers.js";
import Team from "@/classes/Team.js";

export default {
  name: "HrResearch",
  props: ["user", "profile", "researches", "session", "currentUniverseId"],
  components: {
    VueApexCharts,
  },
  setup() {
    return {
      dataFormater,
      dataExtractor,
    };
  },
  data() {
    return {
      unsub: [],
      allTeams: [],
      // teams : [],
      barChartOptions: {
        chart: {
          type: "bar",
          stacked: true,
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          height: 450,
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        stroke: {
          width: 0,
        },
        states: {
          active: {
            filter: {
              type: "none",
            },
          },
        },
        legend: {
          position: "bottom",
        },
        xaxis: {
          categories: [
            "Équipe 1",
            "Équipe 2",
            "Équipe 3",
            "Équipe 4",
            "Équipe 5",
          ],
        },
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          position: "center",
        },
      },
    };
  },
  async created() {
    let sessionUniverses = await Univers.getBySession(this.session.id);
    let tmp_teams = [];

    sessionUniverses.forEach((univers) => {
      this.unsub.push(
        Team.listenByUnivers(univers.id, (teams) => {
          // teams.forEach((team) => {
          //     tmp_teams.push(team)
          // })
          // tmp_teams.sort((a, b) => {
          //     return a.name.localeCompare(b.name)
          // })
          // this.teams = tmp_teams

          let updatedTeams = teams.map((team) => ({
            ...team,
          }));
          this.allTeams = [...this.allTeams, ...updatedTeams].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        })
      );
    });
  },

  computed: {
    filteredTeams() {
      // console.log("ALL TEAMS", this.allTeams);
      return this.allTeams.filter(
        // (team) => team.univers === "VMi5gC8DgYVArMFHPLY4"
        (team) => team.univers === this.currentUniverseId
      );
    },
  },
  //   watch: {
  //     filteredTeams(newVal) {
  //       //   console.log("Filtered Teams HR:", newVal);
  //     },
  //   },
  methods: {
    exportTable() {
      dataExtractor.exportTable("researchWraper", "hrResearch", "Etude RH");
    },
    computeOptions() {
      let options = JSON.parse(JSON.stringify(this.barChartOptions));
      options.xaxis.categories = [];
      for (let team of this.filteredTeams) {
        options.xaxis.categories.push(team.name + "-" + team.publicName);
      }
      return options;
    },
    computeSeries() {
      let series = [
        {
          name: "Ouvriers",
          data: [],
        },
        {
          name: "Employés",
          data: [],
        },
        {
          name: "Chefs de production",
          data: [],
        },
        {
          name: "Managers",
          data: [],
        },
        {
          name: "Commerciaux",
          data: [],
        },
      ];

      for (let team of this.filteredTeams) {
        if (
          this.researches &&
          this.researches.hrResearch &&
          this.researches.hrResearch[team.id] &&
          this.researches.hrResearch[team.id].staffAmount
        ) {
          series[0].data.push(
            this.researches.hrResearch[team.id].staffAmount.workers
          );
          series[1].data.push(
            this.researches.hrResearch[team.id].staffAmount.employees
          );
          series[2].data.push(
            this.researches.hrResearch[team.id].staffAmount.productManagers
          );
          series[3].data.push(
            this.researches.hrResearch[team.id].staffAmount.serviceManagers
          );
          series[4].data.push(
            this.researches.hrResearch[team.id].staffAmount.salesmen
          );
        }
      }

      return series;
    },
  },
};
</script>
