<template>
  <div class="mt-5">
    <v-row class="d-flex flex-row align-center justify-center">
      <v-col cols="12" md="4">
        <v-select
          label="Production"
          :items="productions"
          item-text="data.name"
          return-object
          v-model="selectedProduction"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="selectedProduction">
      <v-col
        cols="12"
        class="d-flex flex-row align-center justify-center flex-wrap"
      >
        <v-btn
          class="ma-2"
          color="success"
          outlined
          @click="exportTable"
          v-if="researches"
        >
          <v-icon>mdi-file-excel-outline</v-icon>
          <span>Exporter cette étude</span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th class="by-1 bx-1" colspan="4">Intitulé</th>
              <th class="by-1 br-1 text-center">Quantité</th>
              <th class="by-1 br-1 text-center">Coût unitaire</th>
              <th class="by-1 br-1 text-center">Coût total</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="
                selectedProduction.type == 'products' &&
                researches.costsResearch[selectedProduction.type][
                  selectedProduction.id
                ] &&
                !isNaN(
                  researches.costsResearch[selectedProduction.type][
                    selectedProduction.id
                  ].marketplaceAmount
                ) &&
                session.displayParameters.marketplace
              "
            >
              <td class="bb-1 bx-1" colspan="4">
                <h2>Valeur des achats marketplace</h2>
              </td>
              <td class="bb-1 br-1 text-center">
                <h3>
                  {{
                    researches.costsResearch[selectedProduction.type][
                      selectedProduction.id
                    ].marketplaceAmount
                  }}
                </h3>
              </td>
              <td class="bb-1 br-1 text-center">
                <h3>
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].marketplaceAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].marketplaceValue /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].marketplaceAmount
                        : 0
                    )
                  }}
                </h3>
              </td>
              <td class="bb-1 br-1 text-center">
                <h3>
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].marketplaceValue
                    )
                  }}
                </h3>
              </td>
            </tr>
            <tr
              v-if="
                selectedProduction.type == 'products' &&
                researches.costsResearch[selectedProduction.type][
                  selectedProduction.id
                ]
              "
            >
              <td class="bb-1 bx-1" colspan="4"><h2>Valeur des stocks</h2></td>
              <td class="bb-1 br-1 text-center">
                <h3>
                  {{
                    researches.costsResearch[selectedProduction.type][
                      selectedProduction.id
                    ].stockAmount
                  }}
                </h3>
              </td>
              <td class="bb-1 br-1 text-center">
                <h3>
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].stockAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].stockValue /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].stockAmount
                        : 0
                    )
                  }}
                </h3>
              </td>
              <td class="bb-1 br-1 text-center">
                <h3>
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].stockValue
                    )
                  }}
                </h3>
              </td>
            </tr>

            <tr @click="toggle" style="cursor: pointer">
              <td class="bb-1 bx-1" colspan="4">
                <h2
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  Coût de la production
                  <span v-if="isOpen">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style="rotate: 90deg"
                    >
                      <path
                        d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm2 12l-4.5 4.5 1.527 1.5 5.973-6-5.973-6-1.527 1.5 4.5 4.5z"
                      />
                    </svg>
                  </span>
                  <span v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style="rotate: -90deg"
                    >
                      <path
                        d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm2 12l-4.5 4.5 1.527 1.5 5.973-6-5.973-6-1.527 1.5 4.5 4.5z"
                      />
                    </svg>
                  </span>
                </h2>
              </td>
              <td class="bb-1 br-1 text-center" :rowspan="computedRowspan">
                <h3>
                  {{
                    researches.costsResearch[selectedProduction.type][
                      selectedProduction.id
                    ].productionCost.productionAmount
                  }}
                </h3>
              </td>
              <td class="bb-1 br-1 text-center">
                <h3>
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.productionAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].productionCost.total /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].productionCost.productionAmount
                        : 0
                    )
                  }}
                </h3>
              </td>
              <td class="bb-1 br-1 text-center">
                <h3>
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.total
                    )
                  }}
                </h3>
              </td>
            </tr>
            <template v-if="isOpen">
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1" colspan="2">
                  Coût de matières premières
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.productionAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].productionCost.rawMaterialCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].productionCost.productionAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.rawMaterialCost
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1" colspan="2">Budget RSE et qualité</td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.productionAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].productionCost.qualityCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].productionCost.productionAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.qualityCost
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1" colspan="2">Salaires de production</td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.productionAmount > 0
                        ? computeProductionSalaryCost(
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].productionCost
                          ) /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].productionCost.productionAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      computeProductionSalaryCost(
                        researches.costsResearch[selectedProduction.type][
                          selectedProduction.id
                        ].productionCost
                      )
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1">Ouvriers</td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.productionAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].productionCost.workersCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].productionCost.productionAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.workersCost
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1">Employés</td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.productionAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].productionCost.employeesCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].productionCost.productionAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.employeesCost
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1">Chefs de production</td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.productionAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].productionCost.productManagersCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].productionCost.productionAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.productManagersCost
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1">Managers</td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.productionAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].productionCost.serviceManagersCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].productionCost.productionAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.serviceManagersCost
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1" colspan="2">Coûts de maintenance</td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.productionAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].productionCost.maintenanceCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].productionCost.productionAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.maintenanceCost
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1" colspan="2">
                  Coûts de structures généraux
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.productionAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].productionCost.generalStructuralCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].productionCost.productionAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.generalStructuralCost
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1" colspan="2">
                  Coûts specifiques machines
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.productionAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].productionCost.machineStructuralCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].productionCost.productionAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.machineStructuralCost
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1" colspan="2">Amortissements machines</td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.productionAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].productionCost.amortisationCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].productionCost.productionAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].productionCost.amortisationCost
                    )
                  }}
                </td>
              </tr>
            </template>
            <!-- <tr>
              <td colspan="7" class="border" style="height: 5px"></td>
            </tr> -->

            <tr @click="toggle2" style="cursor: pointer">
              <td class="bb-1 bx-1" colspan="4">
                <h2
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  Coût de vente
                  <span v-if="isOpen2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style="rotate: 90deg"
                    >
                      <path
                        d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm2 12l-4.5 4.5 1.527 1.5 5.973-6-5.973-6-1.527 1.5 4.5 4.5z"
                      />
                    </svg>
                  </span>
                  <span v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      style="rotate: -90deg"
                    >
                      <path
                        d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm2 12l-4.5 4.5 1.527 1.5 5.973-6-5.973-6-1.527 1.5 4.5 4.5z"
                      />
                    </svg>
                  </span>
                </h2>
              </td>
              <td class="bb-1 br-1 text-center" :rowspan="computedRowspan2">
                <h3>
                  {{
                    researches.costsResearch[selectedProduction.type][
                      selectedProduction.id
                    ].salesCost.salesAmount
                  }}
                </h3>
              </td>
              <td class="bb-1 br-1 text-center">
                <h3>
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].salesCost.salesAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].salesCost.total /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].salesCost.salesAmount
                        : 0
                    )
                  }}
                </h3>
              </td>
              <td class="bb-1 br-1 text-center">
                <h3>
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].salesCost.total
                    )
                  }}
                </h3>
              </td>
            </tr>
            <template v-if="isOpen2">
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1" colspan="2">Coûts de distribution</td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].salesCost.salesAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].salesCost.distributionCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].salesCost.salesAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].salesCost.distributionCost
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1" colspan="2">Salaires des vendeurs</td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].salesCost.salesAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].salesCost.salesmenCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].salesCost.salesAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].salesCost.salesmenCost
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1" colspan="2">Commissions</td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].salesCost.salesAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].salesCost.commissionCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].salesCost.salesAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].salesCost.commissionCost
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1" colspan="2">Coûts spécifiques de zone</td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].salesCost.salesAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].salesCost.zoneCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].salesCost.salesAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].salesCost.zoneCost
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1" colspan="2">Budget communication</td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].salesCost.salesAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].salesCost.communicationCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].salesCost.salesAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].salesCost.communicationCost
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="bb-1 bl-1"></td>
                <td class="bb-1"></td>
                <td class="bb-1 br-1" colspan="2">Budget marketing</td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].salesCost.salesAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].salesCost.marketingCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].salesCost.salesAmount
                        : 0
                    )
                  }}
                </td>
                <td class="bb-1 br-1 text-center">
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].salesCost.marketingCost
                    )
                  }}
                </td>
              </tr>
            </template>
            <!-- <tr>
              <td colspan="7" class="border" style="height: 5px"></td>
            </tr> -->
            <tr>
              <td class="bb-1 bx-1" colspan="4">
                <h2>
                  Coût de revient du produit vendu
                  <v-tooltip bottom max-width="600" color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="accent" icon small v-bind="attrs" v-on="on">
                        <v-icon small>mdi-help</v-icon>
                      </v-btn>
                    </template>
                    <span
                      v-text="
                        'Les ventes suivent toujours l\'ordre de priorité suivant : Marketplace > Stocks > Production'
                      "
                    ></span>
                  </v-tooltip>
                </h2>
              </td>
              <td class="bb-1 br-1 text-center">
                <h3>
                  {{
                    researches.costsResearch[selectedProduction.type][
                      selectedProduction.id
                    ].totalAmount
                  }}
                </h3>
              </td>
              <td class="bb-1 br-1 text-center">
                <h3>
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].totalAmount > 0
                        ? researches.costsResearch[selectedProduction.type][
                            selectedProduction.id
                          ].totalCost /
                            researches.costsResearch[selectedProduction.type][
                              selectedProduction.id
                            ].totalAmount
                        : 0
                    )
                  }}
                </h3>
              </td>
              <td class="bb-1 br-1 text-center">
                <h3>
                  {{
                    dataFormater.formatMoney(
                      researches.costsResearch[selectedProduction.type][
                        selectedProduction.id
                      ].totalCost
                    )
                  }}
                </h3>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dataFormater from "@/assets/functions/dataFormater.js";
import dataExtractor from "@/assets/functions/dataExtractor.js";

export default {
  name: "CostsResearch",
  props: ["user", "profile", "researches", "session"],
  setup() {
    return {
      dataFormater,
      dataExtractor,
    };
  },
  data() {
    return {
      selectedProduction: null,
      isOpen: false,
      isOpen2: false,
    };
  },
  computed: {
    computedRowspan() {
      return this.isOpen ? 12 : 1;
    },
    computedRowspan2() {
      return this.isOpen2 ? 7 : 1;
    },
    productions() {
      let productions = [];
      let products = [];
      let services = [];
      for (let productId in this.session.productsConfig) {
        products.push({
          type: "products",
          id: productId,
          data: this.session.productsConfig[productId],
        });
      }
      for (let serviceId in this.session.servicesConfig) {
        services.push({
          type: "services",
          id: serviceId,
          data: this.session.servicesConfig[serviceId],
        });
      }
      products.sort((a, b) => a.data.name.localeCompare(b.data.name));
      services.sort((a, b) => a.data.name.localeCompare(b.data.name));
      productions = products.concat(services);
      return productions;
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    toggle2() {
      this.isOpen2 = !this.isOpen2;
    },
    exportTable() {
      dataExtractor.exportTable(
        "researchWraper",
        "costsResearch",
        "Etude des coûts " + this.selectedProduction.data.name
      );
    },
    computeProductionSalaryCost(productionCost) {
      let salaryCost =
        productionCost.workersCost +
        productionCost.employeesCost +
        productionCost.productManagersCost +
        productionCost.serviceManagersCost;
      return salaryCost;
    },
  },
};
</script>
