<template>
  <div class="mt-5">
    <div class="mt-10">
      <div class="d-flex flex-row align-center justify-center flex-wrap">
        <div class="ma-5" style="min-width: 800px">
          <h2 class="text-center"></h2>
          <VueApexCharts
            :options="radarChartOptions"
            :series="computeSeries(researches.rseResearch)"
          ></VueApexCharts>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import dataFormater from "@/assets/functions/dataFormater.js";

export default {
  name: "RseResearch",
  props: ["user", "profile", "researches", "session"],
  components: {
    VueApexCharts,
  },
  setup() {
    return {
      dataFormater,
    };
  },
  data() {
    return {
      radarChartOptions: {
        chart: {
          type: "radar",
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        fill: {
          opacity: 0.2,
        },
        markers: {
          size: 5,
        },
        stroke: {
          width: 3,
        },
        states: {
          active: {
            filter: {
              type: "none",
            },
          },
        },
        legend: {
          position: "bottom",
        },
        xaxis: {
          categories: [
            "Social",
            "Expedition",
            "Fournisseurs",
            "Energie",
            "Empreinte carbone",
          ],
        },
        yaxis: {
          min: 0,
          max: 1,
          tickAmount: 5,
          labels: {
            formatter: function (val) {
              return dataFormater.formatPercentage(
                Math.round(val * 10000) / 100
              );
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          position: "center",
        },
        responsive: [
          {
            breakpoint: Infinity,
            options: {
              chart: {
                height: "600",
              },
            },
          },
        ],
      },
    };
  },
  methods: {
    computeSeries(rseResearch) {
      //   console.log("rseResearch", rseResearch);
      let serie = {
        name: "Score",
        data: [],
      };
      serie.data.push(rseResearch.social);
      serie.data.push(rseResearch.transport);
      serie.data.push(rseResearch.rawMaterial);
      serie.data.push(rseResearch.energy);
      serie.data.push(rseResearch.carbon);

      let series = [];
      series.push(serie);

      return series;
    },
  },
};
</script>
