var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5"},[_c('v-row',{staticClass:"d-flex flex-row align-center justify-center"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"label":"Zone","items":_vm.zones,"item-text":"name","return-object":""},model:{value:(_vm.selectedZone),callback:function ($$v) {_vm.selectedZone=$$v},expression:"selectedZone"}})],1)],1),(_vm.selectedZone)?_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row align-center justify-center flex-wrap",attrs:{"cols":"12"}},[(_vm.researches)?_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","outlined":""},on:{"click":_vm.exportTable}},[_c('v-icon',[_vm._v("mdi-file-excel-outline")]),_c('span',[_vm._v("Exporter cette étude")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[(
          _vm.productsList != null &&
          _vm.servicesList != null &&
          _vm.productsList != undefined &&
          _vm.servicesList != undefined
        )?_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"by-1 br-1 bl-1 text-center text-h6",attrs:{"colspan":"2"}},[_vm._v(" General ")]),_vm._l((_vm.productsList),function(product){return _c('th',{staticClass:"by-1 br-1 text-center text-h6",attrs:{"colspan":"5"},domProps:{"textContent":_vm._s(product.name)}})}),_vm._l((_vm.servicesList),function(service){return _c('th',{staticClass:"by-1 br-1 text-center text-h6",attrs:{"colspan":"5"},domProps:{"textContent":_vm._s(service.name)}})})],2),_c('tr',[_c('th',{staticClass:"bb-1 br-1 bl-1 text-center"},[_vm._v("Équipe")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Budget communication")]),_vm._l((_vm.productsList),function(product){return (_vm.productsList)?[_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Budget marketing")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Prix")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Ventes")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Valeur des ventes")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Parts de marché")])]:_vm._e()}),_vm._l((_vm.servicesList),function(service){return (_vm.servicesList)?[_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Budget marketing")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Prix")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Ventes")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Valeur des ventes")]),_c('th',{staticClass:"bb-1 br-1 text-center"},[_vm._v("Parts de marché")])]:_vm._e()})],2)]),_c('tbody',_vm._l((_vm.filteredTeams),function(team){return (_vm.productsList != undefined)?_c('tr',{key:team.id},[_c('td',{staticClass:"bb-1 br-1 bl-1 text-center",domProps:{"textContent":_vm._s(team.name + '-' + team.publicName)}}),(
                _vm.researches &&
                _vm.researches.marketResearch &&
                _vm.researches.marketResearch[team.id] &&
                _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid]
              )?_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                _vm.dataFormater.formatMoney(
                  _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid]
                    .communicationBudget
                )
              )}}):_vm._e(),_vm._l((_vm.productsList),function(product){return (
                _vm.productsList &&
                _vm.researches &&
                _vm.researches.marketResearch &&
                _vm.researches.marketResearch[team.id] &&
                _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid] &&
                _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid]
                  .products[product.uuid]
              )?[_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatMoney(
                    _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid]
                      .products[product.uuid].marketingBudget
                  )
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatMoney(
                    _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid]
                      .products[product.uuid].price
                  )
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid]
                    .products[product.uuid].sales
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatMoney(
                    _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid]
                      .products[product.uuid].salesValue
                  )
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatPercentage(
                    _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid]
                      .products[product.uuid].marketShare * 100
                  )
                )}})]:_vm._e()}),_vm._l((_vm.servicesList),function(service){return (
                _vm.servicesList &&
                _vm.researches &&
                _vm.researches.marketResearch &&
                _vm.researches.marketResearch[team.id] &&
                _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid] &&
                _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid]
                  .services[service.uuid]
              )?[_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatMoney(
                    _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid]
                      .services[service.uuid].marketingBudget
                  )
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatMoney(
                    _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid]
                      .services[service.uuid].price
                  )
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid]
                    .services[service.uuid].sales
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatMoney(
                    _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid]
                      .services[service.uuid].salesValue
                  )
                )}}),_c('td',{staticClass:"bb-1 br-1 text-center",domProps:{"textContent":_vm._s(
                  _vm.dataFormater.formatPercentage(
                    _vm.researches.marketResearch[team.id][_vm.selectedZone.uuid]
                      .services[service.uuid].marketShare * 100
                  )
                )}})]:_vm._e()})],2):_vm._e()}),0)]):_vm._e()],1)],1):_vm._e(),(_vm.selectedZone)?_c('div',{staticClass:"mt-10"},[_c('h2',{staticClass:"mb-5 text-center"},[_vm._v("Parts de marché :")]),(
        _vm.productsList != null &&
        _vm.servicesList != null &&
        _vm.productsList != undefined &&
        _vm.servicesList != undefined
      )?_c('div',{staticClass:"d-flex flex-row align-start justify-center flex-wrap"},[_vm._l((_vm.productsList),function(product){return (
          _vm.computeProductSeries(product).length > 0 &&
          _vm.computeProductSeries(product).reduce((a, b) => a + b) > 0
        )?_c('v-card',{staticClass:"ma-5",staticStyle:{"height":"400px","width":"400px"}},[_c('h3',{staticClass:"py-3 text-center",domProps:{"textContent":_vm._s(product.name)}}),_c('VueApexCharts',{attrs:{"options":_vm.computeOptions(),"series":_vm.computeProductSeries(product)}})],1):_c('v-card',{staticClass:"ma-5",staticStyle:{"height":"400px","width":"400px"}},[_c('h3',{staticClass:"py-3 text-center",domProps:{"textContent":_vm._s(product.name)}}),_c('h2',{staticClass:"my-10 text-center error--text",domProps:{"textContent":_vm._s('Aucun ' + product.name + ' Vendus !')}})])}),_vm._l((_vm.servicesList),function(service){return (
          _vm.computeServiceSeries(service).length > 0 &&
          _vm.computeServiceSeries(service).reduce((a, b) => a + b) > 0
        )?_c('v-card',{staticClass:"ma-5",staticStyle:{"height":"400px","width":"400px"}},[_c('h3',{staticClass:"py-3 text-center",domProps:{"textContent":_vm._s(service.name)}}),_c('VueApexCharts',{attrs:{"options":_vm.computeOptions(),"series":_vm.computeServiceSeries(service)}})],1):_c('v-card',{staticClass:"ma-5",staticStyle:{"height":"400px","width":"400px"}},[_c('h3',{staticClass:"py-3 text-center",domProps:{"textContent":_vm._s(service.name)}}),_c('h2',{staticClass:"my-10 text-center error--text",domProps:{"textContent":_vm._s('Aucun ' + service.name + ' Vendus !')}})])})],2):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }