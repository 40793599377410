h3
<template>
  <div class="mt-5">
    <v-row class="d-flex flex-row align-center justify-center">
      <v-col cols="12" md="4">
        <v-select
          label="Zone"
          :items="zones"
          item-text="name"
          return-object
          v-model="selectedZone"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="selectedZone">
      <v-col
        cols="12"
        class="d-flex flex-row align-center justify-center flex-wrap"
      >
        <v-btn
          class="ma-2"
          color="success"
          outlined
          @click="exportTable"
          v-if="researches"
        >
          <v-icon>mdi-file-excel-outline</v-icon>
          <span>Exporter cette étude</span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-simple-table
          v-if="
            productsList != null &&
            servicesList != null &&
            productsList != undefined &&
            servicesList != undefined
          "
        >
          <thead>
            <tr>
              <th colspan="2" class="by-1 br-1 bl-1 text-center text-h6">
                General
              </th>
              <th
                colspan="5"
                class="by-1 br-1 text-center text-h6"
                v-for="product in productsList"
                v-text="product.name"
              ></th>
              <th
                colspan="5"
                class="by-1 br-1 text-center text-h6"
                v-for="service in servicesList"
                v-text="service.name"
              ></th>
            </tr>
            <tr>
              <th class="bb-1 br-1 bl-1 text-center">Équipe</th>
              <th class="bb-1 br-1 text-center">Budget communication</th>
              <template v-if="productsList" v-for="product in productsList">
                <th class="bb-1 br-1 text-center">Budget marketing</th>
                <th class="bb-1 br-1 text-center">Prix</th>
                <th class="bb-1 br-1 text-center">Ventes</th>
                <th class="bb-1 br-1 text-center">Valeur des ventes</th>
                <th class="bb-1 br-1 text-center">Parts de marché</th>
              </template>
              <template v-if="servicesList" v-for="service in servicesList">
                <th class="bb-1 br-1 text-center">Budget marketing</th>
                <th class="bb-1 br-1 text-center">Prix</th>
                <th class="bb-1 br-1 text-center">Ventes</th>
                <th class="bb-1 br-1 text-center">Valeur des ventes</th>
                <th class="bb-1 br-1 text-center">Parts de marché</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="team in filteredTeams"
              :key="team.id"
              v-if="productsList != undefined"
            >
              <td
                class="bb-1 br-1 bl-1 text-center"
                v-text="team.name + '-' + team.publicName"
              ></td>
              <td
                class="bb-1 br-1 text-center"
                v-if="
                  researches &&
                  researches.marketResearch &&
                  researches.marketResearch[team.id] &&
                  researches.marketResearch[team.id][selectedZone.uuid]
                "
                v-text="
                  dataFormater.formatMoney(
                    researches.marketResearch[team.id][selectedZone.uuid]
                      .communicationBudget
                  )
                "
              ></td>

              <template
                v-if="
                  productsList &&
                  researches &&
                  researches.marketResearch &&
                  researches.marketResearch[team.id] &&
                  researches.marketResearch[team.id][selectedZone.uuid] &&
                  researches.marketResearch[team.id][selectedZone.uuid]
                    .products[product.uuid]
                "
                v-for="product in productsList"
              >
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatMoney(
                      researches.marketResearch[team.id][selectedZone.uuid]
                        .products[product.uuid].marketingBudget
                    )
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatMoney(
                      researches.marketResearch[team.id][selectedZone.uuid]
                        .products[product.uuid].price
                    )
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    researches.marketResearch[team.id][selectedZone.uuid]
                      .products[product.uuid].sales
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatMoney(
                      researches.marketResearch[team.id][selectedZone.uuid]
                        .products[product.uuid].salesValue
                    )
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatPercentage(
                      researches.marketResearch[team.id][selectedZone.uuid]
                        .products[product.uuid].marketShare * 100
                    )
                  "
                ></td>
              </template>

              <template
                v-if="
                  servicesList &&
                  researches &&
                  researches.marketResearch &&
                  researches.marketResearch[team.id] &&
                  researches.marketResearch[team.id][selectedZone.uuid] &&
                  researches.marketResearch[team.id][selectedZone.uuid]
                    .services[service.uuid]
                "
                v-for="service in servicesList"
              >
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatMoney(
                      researches.marketResearch[team.id][selectedZone.uuid]
                        .services[service.uuid].marketingBudget
                    )
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatMoney(
                      researches.marketResearch[team.id][selectedZone.uuid]
                        .services[service.uuid].price
                    )
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    researches.marketResearch[team.id][selectedZone.uuid]
                      .services[service.uuid].sales
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatMoney(
                      researches.marketResearch[team.id][selectedZone.uuid]
                        .services[service.uuid].salesValue
                    )
                  "
                ></td>
                <td
                  class="bb-1 br-1 text-center"
                  v-text="
                    dataFormater.formatPercentage(
                      researches.marketResearch[team.id][selectedZone.uuid]
                        .services[service.uuid].marketShare * 100
                    )
                  "
                ></td>
              </template>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <div class="mt-10" v-if="selectedZone">
      <h2 class="mb-5 text-center">Parts de marché :</h2>
      <div
        class="d-flex flex-row align-start justify-center flex-wrap"
        v-if="
          productsList != null &&
          servicesList != null &&
          productsList != undefined &&
          servicesList != undefined
        "
      >
        <v-card
          class="ma-5"
          v-for="product in productsList"
          v-if="
            computeProductSeries(product).length > 0 &&
            computeProductSeries(product).reduce((a, b) => a + b) > 0
          "
          style="height: 400px; width: 400px"
        >
          <h3 class="py-3 text-center" v-text="product.name"></h3>
          <VueApexCharts
            :options="computeOptions()"
            :series="computeProductSeries(product)"
          ></VueApexCharts>
        </v-card>
        <v-card class="ma-5" v-else style="height: 400px; width: 400px">
          <h3 class="py-3 text-center" v-text="product.name"></h3>
          <h2
            class="my-10 text-center error--text"
            v-text="'Aucun ' + product.name + ' Vendus !'"
          ></h2>
        </v-card>
        <v-card
          class="ma-5"
          v-for="service in servicesList"
          v-if="
            computeServiceSeries(service).length > 0 &&
            computeServiceSeries(service).reduce((a, b) => a + b) > 0
          "
          style="height: 400px; width: 400px"
        >
          <h3 class="py-3 text-center" v-text="service.name"></h3>
          <VueApexCharts
            :options="computeOptions()"
            :series="computeServiceSeries(service)"
          ></VueApexCharts>
        </v-card>
        <v-card class="ma-5" v-else style="height: 400px; width: 400px">
          <h3 class="py-3 text-center" v-text="service.name"></h3>
          <h2
            class="my-10 text-center error--text"
            v-text="'Aucun ' + service.name + ' Vendus !'"
          ></h2>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import dataFormater from "@/assets/functions/dataFormater.js";
import dataExtractor from "@/assets/functions/dataExtractor.js";

import Univers from "@/classes/Univers.js";
import Team from "@/classes/Team.js";

export default {
  name: "MarketResearch",
  props: ["user", "profile", "researches", "session", "currentUniverseId"],
  components: {
    VueApexCharts,
  },
  setup() {
    return {
      dataFormater,
      dataExtractor,
    };
  },
  data() {
    return {
      unsub: [],
      selectedZone: null,
      allTeams: [],
      // teams: [],
      pieChartOptions: {
        chart: {
          type: "pie",
        },
        labels: ["Équipe 1", "Équipe 2", "Équipe 3", "Équipe 4", "Équipe 5"],
        stroke: {
          width: 0,
        },
        states: {
          active: {
            filter: {
              type: "none",
            },
          },
        },
        tooltip: {
          enabled: true,
        },
        legend: {
          position: "bottom",
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
          },
        },
      },
    };
  },
  async created() {
    let sessionUniverses = await Univers.getBySession(this.session.id);

    // console.log("sessionUniverses", sessionUniverses);
    // console.log("CONSOLE UNIVERSE ID IN CHILD VUE", this.currentUniverseId);

    // let tmp_teams = [];

    sessionUniverses.forEach((univers) => {
      this.unsub.push(
        Team.listenByUnivers(univers.id, (teams) => {
          // teams.forEach((team) => {
          //   tmp_teams.push(team);
          // });
          // tmp_teams.sort((a, b) => {
          //   return a.name.localeCompare(b.name);
          // });
          // this.teams = tmp_teams;

          let updatedTeams = teams.map((team) => ({
            ...team,
          }));
          this.allTeams = [...this.allTeams, ...updatedTeams].sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        })
      );
    });
  },
  computed: {
    zones() {
      return Object.values(this.session.zonesConfig);
    },

    filteredTeams() {
      // console.log("ALL TEAMS", this.allTeams);
      return this.allTeams.filter(
        // (team) => team.univers === "VMi5gC8DgYVArMFHPLY4"
        (team) => team.univers === this.currentUniverseId
      );
    },
    productsList() {
      if (this.selectedZone == null || this.selectedZone == undefined) {
        return [];
      }

      //   researches.marketResearch[team.id][selectedZone.uuid]
      //                   .communicationBudget

      // console.log("Researches", this.researches.marketResearch);
      // console.log("selectedZone", this.selectedZone);

      let idList = [];
      if (
        this.researches != undefined &&
        this.researches.marketResearch != undefined &&
        this.researches != null &&
        this.researches.marketResearch != null
      ) {
        idList = Object.keys(
          this.researches.marketResearch[
            Object.keys(this.researches.marketResearch)[0]
          ][this.selectedZone.uuid].products
        );
      }

      let products = [];
      for (let productId of idList) {
        products.push(this.session.productsConfig[productId]);
      }
      products.sort((a, b) => a.name.localeCompare(b.name));

      return products;
    },
    servicesList() {
      if (this.selectedZone == null || this.selectedZone == undefined) {
        return [];
      }
      let idList = [];
      if (
        this.researches != undefined &&
        this.researches.marketResearch != undefined &&
        this.researches != null &&
        this.researches.marketResearch != null
      ) {
        idList = Object.keys(
          this.researches.marketResearch[
            Object.keys(this.researches.marketResearch)[0]
          ][this.selectedZone.uuid].services
        );
      }

      let services = [];
      for (let serviceId of idList) {
        services.push(this.session.servicesConfig[serviceId]);
      }
      services.sort((a, b) => a.name.localeCompare(b.name));

      return services;
    },
  },

  // watch: {
  //   filteredTeams(newVal) {
  //     console.log("Filtered Teams:", newVal);
  //   },
  // },
  methods: {
    exportTable() {
      dataExtractor.exportTable(
        "researchWraper",
        "marketResearch",
        "Etude de Marché " + this.selectedZone.name
      );
    },
    computeOptions() {
      let options = JSON.parse(JSON.stringify(this.pieChartOptions));
      options.labels = [];
      for (let team of this.filteredTeams) {
        options.labels.push(team.name + "-" + team.publicName);
      }

      return options;
    },
    computeProductSeries(product) {
      let series = [];
      for (let team of this.filteredTeams) {
        if (
          this.researches &&
          this.researches.marketResearch &&
          this.researches.marketResearch[team.id] &&
          this.researches.marketResearch[team.id][this.selectedZone.uuid] &&
          this.researches.marketResearch[team.id][this.selectedZone.uuid]
            .products[product.uuid]
        ) {
          series.push(
            Math.round(
              this.researches.marketResearch[team.id][this.selectedZone.uuid]
                .products[product.uuid].marketShare * 10000
            ) / 100
          );
        }
      }

      // console.log("PRODUCT SERIES", series);
      return series;
    },
    computeServiceSeries(service) {
      let series = [];
      for (let team of this.filteredTeams) {
        if (
          this.researches &&
          this.researches.marketResearch &&
          this.researches.marketResearch[team.id] &&
          this.researches.marketResearch[team.id][this.selectedZone.uuid] &&
          this.researches.marketResearch[team.id][this.selectedZone.uuid]
            .services[service.uuid]
        ) {
          series.push(
            Math.round(
              this.researches.marketResearch[team.id][this.selectedZone.uuid]
                .services[service.uuid].marketShare * 10000
            ) / 100
          );
        }
      }
      // console.log("SERVICE SERIES", series);
      return series;
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      unsub();
    });
  },
};
</script>
