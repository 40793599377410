import {
  getFirestore,
  doc,
  collection,
  query,
  where,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";

let db = getFirestore();
let collectionName = "researches";

class Research {
  constructor(
    id,
    period,
    team,
    purchased,
    marketResearch,
    hrResearch,
    attractivityResearch,
    rseResearch,
    costsResearch
  ) {
    this.id = id;
    this.period = period;
    this.team = team;
    this.purchased = purchased;
    this.marketResearch = marketResearch;
    this.hrResearch = hrResearch;
    this.attractivityResearch = attractivityResearch;
    this.rseResearch = rseResearch;
    this.costsResearch = costsResearch;
  }

  static async initializeOne(periodId, teamID) {
    let tmp_purchased = {
      marketResearch: false,
      hrResearch: false,
      attractivityResearch: false,
      rseResearch: false,
      costsResearch: false,
    };
    let tmp_marketResearch = [];
    let tmp_hrResearch = {
      socialBudget: 0,
      socialIndex: [],
      staffAmount: [],
      commission: 0,
    };
    let tmp_attractivityResearch = {};
    let tmp_rseResearch = {
      social: 0,
      expedition: 0,
      rawMaterial: 0,
      energy: 0,
      carbon: 0,
    };
    let tmp_costsResearch = {
      products: [],
      services: [],
    };

    let tmp_research = new Research(
      null,
      periodId,
      teamID,
      tmp_purchased,
      tmp_marketResearch,
      tmp_hrResearch,
      tmp_attractivityResearch,
      tmp_rseResearch,
      tmp_costsResearch
    );
    return tmp_research;
  }

  static async getAll() {
    let collectionRef = collection(db, collectionName);
    let response = await getDocs(collectionRef);

    let researches = [];

    response.forEach((doument) => {
      let data = doument.data();
      let tmp_research = new Research(
        doument.id,
        data.period,
        data.team,
        data.purchased,
        data.marketResearch,
        data.hrResearch,
        data.attractivityResearch,
        data.rseResearch,
        data.costsResearch
      );
      researches.push(tmp_research);
    });

    return researches;
  }

  static async getById(id) {
    let documentRef = doc(db, collectionName, id);
    let response = await getDoc(documentRef);

    let data = response.data();
    let research = new Research(
      response.id,
      data.period,
      data.team,
      data.purchased,
      data.marketResearch,
      data.hrResearch,
      data.attractivityResearch,
      data.rseResearch,
      data.costsResearch
    );

    return research;
  }

  static async getByPeriod(periodId) {
    let collectionRef = collection(db, collectionName);
    let documentQuery = query(collectionRef, where("period", "==", periodId));
    let response = await getDocs(documentQuery);

    let researches = [];

    response.forEach((document) => {
      let data = document.data();
      let tmp_research = new Research(
        document.id,
        data.period,
        data.team,
        data.purchased,
        data.marketResearch,
        data.hrResearch,
        data.attractivityResearch,
        data.rseResearch,
        data.costsResearch
      );
      researches.push(tmp_research);
    });

    return researches;
  }

  static async getByTeam(teamId) {
    let collectionRef = collection(db, collectionName);
    let documentQuery = query(collectionRef, where("team", "==", teamId));
    let response = await getDocs(documentQuery);

    let researches = [];

    response.forEach((document) => {
      let data = document.data();
      let tmp_research = new Research(
        document.id,
        data.period,
        data.team,
        data.purchased,
        data.marketResearch,
        data.hrResearch,
        data.attractivityResearch,
        data.rseResearch,
        data.costsResearch
      );
      researches.push(tmp_research);
    });

    return researches;
  }

  static async getByPeriodAndTeam(periodId, teamId) {
    let collectionRef = collection(db, collectionName);
    let documentQuery = query(
      collectionRef,
      where("period", "==", periodId),
      where("team", "==", teamId)
    );
    let response = await getDocs(documentQuery);

    let researches = [];

    response.forEach((document) => {
      let data = document.data();
      let tmp_research = new Research(
        document.id,
        data.period,
        data.team,
        data.purchased,
        data.marketResearch,
        data.hrResearch,
        data.attractivityResearch,
        data.rseResearch,
        data.costsResearch
      );
      researches.push(tmp_research);
    });

    return researches;
  }

  static listenAll(callback = null) {
    let collectionRef = collection(db, collectionName);
    let unsub = onSnapshot(collectionRef, (snapshot) => {
      let researches = [];

      snapshot.forEach((document) => {
        let data = document.data();
        let tmp_research = new Research(
          document.id,
          data.period,
          data.team,
          data.purchased,
          data.marketResearch,
          data.hrResearch,
          data.attractivityResearch,
          data.rseResearch,
          data.costsResearch
        );
        researches.push(tmp_research);
      });

      if (callback != null) {
        callback(researches);
      }
    });
    return unsub;
  }

  static listenById(id, callback = null) {
    let documentRef = doc(db, collectionName, id);
    let unsub = onSnapshot(documentRef, (snapshot) => {
      let data = snapshot.data();
      let research = new Research(
        snapshot.id,
        data.period,
        data.team,
        data.purchased,
        data.marketResearch,
        data.hrResearch,
        data.attractivityResearch,
        data.rseResearch,
        data.costsResearch
      );

      if (callback != null) {
        callback(research);
      }
    });
    return unsub;
  }

  static async listenByPeriod(periodId, callback = null) {
    let collectionRef = collection(db, collectionName);
    let documentsQuery = query(collectionRef, where("period", "==", periodId));

    let unsub = onSnapshot(documentsQuery, (snapshot) => {
      let researches = [];

      snapshot.forEach((document) => {
        let data = document.data();
        let tmp_research = new Research(
          document.id,
          data.period,
          data.team,
          data.purchased,
          data.marketResearch,
          data.hrResearch,
          data.attractivityResearch,
          data.rseResearch,
          data.costsResearch
        );
        researches.push(tmp_research);
      });

      if (callback != null) {
        callback(researches);
      }
    });
    return unsub;
  }

  static async listenByTeam(teamId, callback = null) {
    let collectionRef = collection(db, collectionName);
    let documentsQuery = query(collectionRef, where("team", "==", teamId));

    let unsub = onSnapshot(documentsQuery, (snapshot) => {
      let researches = [];

      snapshot.forEach((document) => {
        let data = document.data();
        let tmp_research = new Research(
          document.id,
          data.period,
          data.team,
          data.purchased,
          data.marketResearch,
          data.hrResearch,
          data.attractivityResearch,
          data.rseResearch,
          data.costsResearch
        );
        researches.push(tmp_research);
      });

      if (callback != null) {
        callback(researches);
      }
    });
    return unsub;
  }

  static async listenByPeriodAndTeam(periodId, teamId, callback = null) {
    let collectionRef = collection(db, collectionName);
    let documentsQuery = query(
      collectionRef,
      where("period", "==", periodId),
      where("team", "==", teamId)
    );

    let unsub = onSnapshot(documentsQuery, (snapshot) => {
      let researches = [];

      snapshot.forEach((document) => {
        let data = document.data();
        let tmp_research = new Research(
          document.id,
          data.period,
          data.team,
          data.purchased,
          data.marketResearch,
          data.hrResearch,
          data.attractivityResearch,
          data.rseResearch,
          data.costsResearch
        );
        researches.push(tmp_research);
      });

      if (callback != null) {
        callback(researches);
      }
    });
    return unsub;
  }

  async save() {
    let collectionRef = collection(db, collectionName);
    if (this.id == null) {
      let response = await addDoc(collectionRef, {
        period: this.period,
        team: this.team,
        purchased: this.purchased,
        marketResearch: this.marketResearch,
        hrResearch: this.hrResearch,
        attractivityResearch: this.attractivityResearch,
        rseResearch: this.rseResearch,
        costsResearch: this.costsResearch,
      });
      this.id = response.id;
    } else {
      await updateDoc(doc(db, collectionName, this.id), {
        period: this.period,
        team: this.team,
        purchased: this.purchased,
        marketResearch: this.marketResearch,
        hrResearch: this.hrResearch,
        attractivityResearch: this.attractivityResearch,
        rseResearch: this.rseResearch,
        costsResearch: this.costsResearch,
      });
    }
    return this;
  }

  delete() {
    let documentRef = doc(db, collectionName, this.id);
    deleteDoc(documentRef);
  }
}

export default Research;
