<template>
    <div class="mt-5">
        <div class="mt-10">
            <div class="d-flex flex-row align-center justify-center flex-wrap">
                <div class="ma-5" style="min-width: 650px;" v-for="zoneData in researches.attractivityResearch">
                    <h2 class="text-center" v-text="zoneData.zone.name"></h2>
                    <VueApexCharts :options="radarChartOptions" :series="computeSeries(zoneData)"></VueApexCharts>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import dataFormater from "@/assets/functions/dataFormater.js"

export default {
    name: "AttractivityResearch",
    props: ["user", "profile", "researches", "session"],
    components: {
        VueApexCharts
    },
    setup() {
        return {
            dataFormater
        }
    },
    data() {
        return {
            radarChartOptions: {
                chart: {
                    type: 'radar',
                    animations: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                },
                fill: {
                    opacity: 0.2
                },
                markers: {
                    size: 5
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    },
                },
                stroke: {
                    width: 3,
                },
                states: {
                    active: {
                        filter: {
                            type: 'none'
                        }
                    }
                },
                legend: {
                    position: 'bottom',
                },
                xaxis: {
                    categories: ['Prix', 'Qualité', 'Marketing', 'Communication', 'Force de vente'],
                },
                yaxis: {
                    min: 0,
                    max: 1,
                    tickAmount: 5,
                    labels: {
                        formatter: function (val) {
                            return dataFormater.formatPercentage(Math.round(val * 10000)/100)
                        }
                    }
                },
                tooltip: {
                    enabled: false
                },
                dataLabels: {
                    position: 'center',
                },
                responsive: [{
                    breakpoint: Infinity,
                    options: {
                        chart: {
                            height: '600',
                        },
                    }
                }]
            },
        }
    },
    methods : {
        computeSeries(zoneData) {
            let productSeries = []
            let serviceSeries = []
            let series
            for(let productId in this.session.productsConfig) {
                let productData = zoneData.products[productId]
                productSeries.push({
                    name : this.session.productsConfig[productId].name,
                    data : [ productData.price, productData.quality, productData.marketing, productData.communication, productData.sellingPower ]
                })
            }
            for(let serviceId in this.session.servicesConfig) {
                let serviceData = zoneData.services[serviceId]
                serviceSeries.push({
                    name : this.session.servicesConfig[serviceId].name,
                    data : [ serviceData.price, serviceData.quality, serviceData.marketing, serviceData.communication, serviceData.sellingPower ]
                })
            }

            productSeries.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            serviceSeries.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            series = productSeries.concat(serviceSeries)

            return series
        }
    }
}
</script>
